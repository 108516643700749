var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer"},[_c('div',{staticClass:"container"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('ul',{staticClass:"footer-list list-unstyled"},[_c('li',{staticClass:"footer-item"},[_c('router-link',{staticClass:"footer-link",attrs:{"to":{
                name: _vm.$route.params.shopId
                  ? 'create member'
                  : 'create member domain',
                params: { shopId: _vm.shop_id },
              }}},[_vm._v(" 新規会員登録 ")])],1),_c('li',{staticClass:"footer-item"},[_c('router-link',{staticClass:"footer-link",attrs:{"to":{
                name: _vm.$route.params.shopId
                  ? 'login shop'
                  : 'login shop domain',
                params: { shopId: _vm.shop_id },
              }}},[_vm._v(" マイページログイン ")])],1)])]),_c('div',{staticClass:"clearfix"}),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"footer-copyright"},[_vm._v(" Copyright © 2022 CDEA - All rights reserved. ")])])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }