<template>
  <div class="footer">
    <div class="container">
      <b-row>
        <b-col cols="12" md="12">
          <ul class="footer-list list-unstyled">
            <li class="footer-item">
              <router-link
                :to="{
                  name: $route.params.shopId
                    ? 'create member'
                    : 'create member domain',
                  params: { shopId: shop_id },
                }"
                class="footer-link"
              >
                新規会員登録
              </router-link>
            </li>
            <li class="footer-item">
              <router-link
                :to="{
                  name: $route.params.shopId
                    ? 'login shop'
                    : 'login shop domain',
                  params: { shopId: shop_id },
                }"
                class="footer-link"
              >
                マイページログイン
              </router-link>
            </li>
          </ul>
        </b-col>
        <div class="clearfix"></div>
        <b-col cols="12">
          <div class="footer-copyright">
            Copyright © 2022 CDEA - All rights reserved.
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Routes } from "../../utils/routes";
import { Constants } from "../../utils/constants";
export default {
  name: "FooterPage404All",
  data() {
    return {
      beginUrl: "/" + Routes.SHOP + "/" + this.$route.params.shopId,
      dataShop: [],
      logoShop: null,
      shop_id: localStorage.getItem(Constants.SHOP_ID),
    };
  },
  created() {},
  computed: {
    ...mapGetters(["success", "error"]),
  },
  watch: {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.footer {
  padding: 20px 0 20px 0;
  position: relative;
  background-color: white;
  border-top: 2px solid #eee;
  &-logo {
    cursor: pointer;
    @media (max-width: 768px) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &-list {
    margin: 0;
    @media (max-width: 768px) {
      padding: 0 90px;
    }
  }
  &-item {
    display: inline-block;
    font-size: 13px;
    @media (max-width: 768px) {
      // text-align: center;
    }
  }
  &-item:not(:first-child) {
    margin: 0 0 0 12px;
    padding: 0 0 0 12px;
    border-left: 1px solid #8e8e8e;
  }
  &-link {
    color: #000;
    font-size: 14px;
    &:hover {
      text-decoration: none;
      color: #817f7f;
    }
  }
  &-copyright {
    margin: 15px 0 0;
    display: block;
    font-size: 12px;
    color: #817f7f;
  }
}
.img-logo-footer {
  width: 286px;
  height: 79px;
}
@media (max-width: 992px) and (min-width: 768px) {
  .img-logo-footer {
    width: unset;
    height: unset;
  }
}
</style>
