<template>
  <div>
    <div class="header d-flex align-items-center justify-content-between">
      <div class="header-logo" v-on:click="goToStore()">
        <router-link
          :to="{ name: $route.params.shopId ? 'store' : 'store domain' }"
          ><div class="img-logo-header">
            <img
              v-if="logoShop"
              :src="logoShop"
              width="100%"
              height="100%"
              style="
                object-fit: contain;
                width: 100%;
                height: 100%;
                object-position: left;
              "
            /><img
              v-else
              src="@/assets/img/logo_default.png"
              width="100%"
              height="100%"
              style="
                object-fit: contain;
                width: 100%;
                height: 100%;
                object-position: left;
              "
            />
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { Constants } from "../../utils/constants";

import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import { Routes } from "../../utils/routes";

export default {
  name: "HeaderPage404All",
  data() {
    return {
      settingsSlick: {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: false,
      },
      settingsSlickSP: {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: false,
      },
      dataMenu: [
        { id: 1, icon: "person-fill", name: "著者" },
        { id: 2, icon: "book", name: "Name 2" },
        { id: 3, icon: "cart", name: "カート" },
        { id: 4, icon: "person-fill", name: "Name 4" },
        { id: 5, icon: "book", name: "Name 5" },
      ],
      isActive: false,
      showModal: false,
      isActiveTabStore: false,
      isActiveTabLibrary: false,
      isLoading: false,
      dataMenu1: [],
      countListCart: 0,
      isShowModal: true,
      beginUrl: "/" + Routes.SHOP + "/" + this.$route.params.shopId,
      keyword: "",
      dataShop: [],
      logoShop: null,
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      is_login: localStorage.getItem(Constants.TOKEN_USER) ? true : false,
    };
  },
  created() {},
};
</script>
<style lang="scss" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.img-logo-header {
  width: 286px;
  height: 79px;
}
.sidebar {
  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateX(100%);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(5px);
    background-color: #0000008f;
    z-index: 1031;
  }
  &-menu {
    position: fixed;
    transform: translateX(100%);
    top: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    border-radius: 50px 0 0 0;
    padding: 60px 40px 0px 40px;
    background: #e79c3a;
    transition: all 0.5s;
    z-index: 1031;
    display: flex;
    flex-flow: column;
    flex-shrink: 1;
    width: 460px;
    .close-sidebar-btn {
      cursor: pointer;
      font-size: 28px;
      color: #fff;
      position: absolute;
      top: 10px;
      right: 25px;
    }
  }

  .menu-sidebar-logout {
    width: 100%;
    height: 110px;
    border-top: 1px solid #95c895;
    display: flex;
    justify-content: center;
    align-items: center;
    .logout-btn {
      padding: 10px;
      font-size: 18px;
      cursor: pointer;
      border-radius: 10px;
      color: #fff;
      &:hover {
        background-color: #7bb97b;
      }
    }
  }

  &-scroll {
    padding-right: 40px;
    overflow: auto;
    height: 100%;
    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #95c895;
      border-radius: 6px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #fff;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #dfdfdf;
    }
    &::-webkit-scrollbar {
      width: 3px;
    }
  }
  &-items {
    width: 330px;
    padding: 15px 0;
    &:not(:first-child) {
      border-top: 1px solid #95c895;
    }

    .sidebar-link {
      text-decoration: none;
      cursor: pointer;
      a {
        &:hover {
          text-decoration: none;
        }
      }
      .sidebar-item {
        padding: 15px 20px;
        font-size: 18px;
        border-radius: 10px;
        color: #fff;
        &:hover {
          background-color: #7bb97b;
        }
      }
    }
  }
  &.active {
    .sidebar-menu {
      transform: translateX(0%);
    }
    .backdrop {
      transform: translateX(0%);
    }
  }
  @media (max-width: 767px) {
    .sidebar {
      &-menu {
        padding: 60px 20px 0 40px;
        width: 380px;
        &-scroll {
          padding-right: 10px;
          &-items {
            width: 270px;
            font-size: 16px;
            a {
              &-item {
                font-size: 16px;
                padding: 15px;
              }
            }
          }
        }
        .close-sidebar-btn {
          font-size: 28px;
          color: #fff;
          cursor: pointer;
          position: absolute;
          top: 10px;
          right: 25px;
        }
      }
    }
  }
}
@media (max-width: 850px) and (min-width: 768px) {
  .img-logo-header {
    width: 210px;
    height: 69px;
  }
}
.slick-next::before {
  color: black !important;
}
.slick-prev::before {
  color: black !important;
}
.img-menu-header {
  width: 100%;
  height: 1em;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.img-menu-header-sidebar {
  display: flex;
  width: 100%;
  height: 3em;
  img {
    width: 6%;
    height: 100%;
    object-fit: contain;
  }
}
</style>
