<template>
  <div class="default-layout">
    <Header />
    <div class="content">
      <router-view :key="$route.fullPath"></router-view>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "./Footer";
import Header from "./Header";
import { Constants } from "../../utils/constants";

export default {
  name: "Layout",
  data() {
    return {
      shopId: this.$route.params.shopId
        ? this.$route.params.shopId
        : localStorage.getItem(Constants.SHOP_ID),
      is_login: localStorage.getItem(Constants.TOKEN_USER) ? true : false,
    };
  },
  components: {
    Header,
    Footer,
  },
};
</script>
<style lang="scss">
// Import Main styles for this application
@import "../../views/default/Default.scss";
</style>
